import * as React from "react";
import { SVGProps } from "react";
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 1.25a.75.75 0 0 1 .75.75v1.25H19A2.75 2.75 0 0 1 21.75 6v14A2.75 2.75 0 0 1 19 22.75H5A2.75 2.75 0 0 1 2.25 20V6A2.75 2.75 0 0 1 5 3.25h2.25V2a.75.75 0 0 1 1.5 0v1.25h6.5V2a.75.75 0 0 1 .75-.75Zm-8.75 3.5V6a.75.75 0 0 0 1.5 0V4.75h6.5V6a.75.75 0 0 0 1.5 0V4.75H19c.69 0 1.25.56 1.25 1.25v3.25H3.75V6c0-.69.56-1.25 1.25-1.25h2.25Zm13 6V20c0 .69-.56 1.25-1.25 1.25H5c-.69 0-1.25-.56-1.25-1.25v-9.25h16.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCalendar;
