import { FilterSection } from "components/AppTables/common/Filter/FilterSection";
import { TableFilter } from "components/AppTables/common/Filter/TableFilter";
import { useTranslation } from "react-i18next";
import { OrganizationsTableFilterProps, ORGANIZATIONS_FILTER_OPTIONS } from "../context";
import React from "react";
import { StatesFilterSection } from "components/AppTables/common/Filter/StatesFilterSection";

export function OrganizationsTableFilter({
  tableService,
  filterSideModelState,
}: Readonly<OrganizationsTableFilterProps>) {
  const { t: tColumns } = useTranslation("translation", {
    keyPrefix: "OrganizationsTable.columns",
  });

  return (
    <TableFilter tableService={tableService} filterSideModelState={filterSideModelState}>
      {({ activeFilters, appliedFilters, onNewFilterApplied, onSingleFilterClear }) => (
        <>
          {activeFilters.map(el => {
            if (el === "contains") {
              return null;
            }

            // states can't be standalone filter
            if (el === "states") {
              return null;
            }

            if (el === "countries") {
              return (
                <React.Fragment key={"countries"}>
                  <FilterSection
                    title={tColumns("countries")}
                    options={ORGANIZATIONS_FILTER_OPTIONS()["countries"]}
                    checkedValues={appliedFilters["countries"] ?? []}
                    onFilterApply={value => onNewFilterApplied("countries", value)}
                    withSearch={true}
                  />

                  <StatesFilterSection
                    checkedValues={appliedFilters["states"] ?? []}
                    countries={appliedFilters["countries"] ?? []}
                    onFilterApply={value => onNewFilterApplied("states", value)}
                    onSingleFilterClear={() => onSingleFilterClear("states")}
                  />
                </React.Fragment>
              );
            }

            return (
              <FilterSection
                key={el}
                title={tColumns(el)}
                options={ORGANIZATIONS_FILTER_OPTIONS()[el]}
                checkedValues={appliedFilters[el] ?? []}
                onFilterApply={value => onNewFilterApplied(el, value)}
              />
            );
          })}
        </>
      )}
    </TableFilter>
  );
}
