import {
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
  setUser,
} from "@sentry/react";
import { Hub } from "aws-amplify/utils";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";
import { getCurrentUserData } from "utils/getCurrentUserData";

// const SentryTraceHosts = (
//   [
//     process.env.VITE_APP_UPDATE_HOST,
//     process.env.VITE_APP_QUERY_HOST,
//     process.env.VITE_APP_CONFIG_HOST,
//   ] as const
// ).filter(v => !!v) as string[];

if (process.env.VITE_APP_SENTRY_ENABLED === "true") {
  init({
    dsn: "https://46bcebd24bd72e98c3f3340dbcd14d6f@o4506581432795136.ingest.sentry.io/4506615212867584",
    ignoreErrors: [
      "ResizeObserver loop completed with undelivered notifications.",
      "Refresh Token has expired",
      "User is not authenticated",
    ],
    environment: process.env.VITE_APP_ENVIRONMENT ?? "env_not_set",
    // beforeSendTransaction(event, hint) {
    //   // remove double start slashes from routes
    //   if (event.transaction_info?.source === "route") {
    //     if (event.transaction?.startsWith("//")) {
    //       event.transaction = event.transaction.slice(1);
    //     }
    //   }
    //   return event;
    // },
    // beforeBreadcrumb(breadcrumb) {
    //   // ! Filter out our config console.warns
    //   if (
    //     breadcrumb.category === "console" &&
    //     breadcrumb.message?.includes("Returning hardcoded value")
    //   ) {
    //     return null;
    //   }

    //   if (
    //     (breadcrumb.level === "info" || breadcrumb.level === "debug") &&
    //     breadcrumb.category === "console"
    //   ) {
    //     return null;
    //   }

    //   return breadcrumb;
    // },
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration({
        stickySession: false,
        maxReplayDuration: 60000, // 1min
        maskAllText: true,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  async function SentryHubListener({ payload, ...rest }: any) {
    switch (payload.event) {
      case "signOut":
        setUser(null);

        break;
      // Configured event comes even if we are not logged in
      // we get Error thrown: no current user data in this case
      case "signIn":
      case "configured":
        try {
          const userData = await getCurrentUserData();
          setUser({ id: userData.currentUserId, tenant: userData.tenantId });
        } catch (error) {
          if (error === "No current user") {
            return;
          }
          throw error;
        }
        break;
      default:
        break;
    }
  }

  Hub.listen("auth", SentryHubListener, "sentry_user_login");
}
