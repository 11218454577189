import classNames from "classnames";
import { isDefaultModel, isFailed, isLoaded, isLoading } from "models/loadable";
import { useCallback, useSyncExternalStore } from "react";
import { avatarCache } from "utils/machines/avatars";
import { useOverviewAPIContext } from "../OverviewAPIContext";
import { RefreshFailedWidget } from "../RefreshFailedWidget";
import { WidgetLoader } from "../WidgetLoader";
import { PatientInfoHeader } from "./PatientInfoHeader";
import "./PatientInfoWidget.scss";
import { PictureResolution } from "utils/helpers/pictures.model";
import { PatientInfoSection } from "./PatientInfoSection";
import { PatientInfoSectionItem } from "./PatientInfoSectionItem";
import { Icon } from "libs/ui/Icon";
import { PatientInfoValue } from "./PatientInfoValue";
import { useTranslation } from "react-i18next";
import { getBMICategory } from "utils/getBMICategory";
import { useLocaleNumberFormatter } from "utils/hooks/useLocaleNumberFormatter";
import { useHasAccess } from "utils/hooks/useHasAccess";
import { generatePath } from "react-router-dom";
import { mainRoutePaths, settingsRoutePaths } from "router";
import { Avatar } from "libs/ui/Avatar";
import { DeviceImage } from "libs/ui/DeviceImage";
import { deviceTypeToSelectOptions } from "utils/mappers/deviceTypeToSelectOptions";

interface Props {
  organizationId: string;
  patientId: string;
  w?: number;
  h?: number;
}

export function PatientInfoWidget({ organizationId, patientId, w, h }: Readonly<Props>) {
  const {
    data: { PatientInfo },
    getWidgetData,
  } = useOverviewAPIContext();

  useSyncExternalStore(avatarCache.subscribe, avatarCache.getState);
  const { numberFormatter } = useLocaleNumberFormatter();
  const { t } = useTranslation("widgets", { keyPrefix: "PatientInfoWidget" });
  const { t: tGeneral } = useTranslation("widgets", { keyPrefix: "PatientInfoWidget.GeneralInfo" });
  const { t: tOrganizational } = useTranslation("widgets", {
    keyPrefix: "PatientInfoWidget.OrganizationalInfo",
  });
  const can = useHasAccess();

  const patientInfoData = isLoaded(PatientInfo) ? PatientInfo.value : null;

  const refreshWidgetData = useCallback(() => {
    getWidgetData(organizationId, patientId, ["PatientInfo"]);
  }, [getWidgetData, patientId, organizationId]);

  const BMICategory = patientInfoData?.bmi
    ? getBMICategory(parseFloat(patientInfoData?.bmi.toString()))
    : "";

  const shouldShowOrganizationalSection = h && Number(h) > 2;

  return (
    <div
      className={classNames("PatientInfoWidget", {
        "PatientInfoWidget--loading": isLoading(PatientInfo) || isDefaultModel(PatientInfo),
        "PatientInfoWidget--small": !shouldShowOrganizationalSection,
      })}
    >
      {isLoading(PatientInfo) || isDefaultModel(PatientInfo) ? (
        <WidgetLoader />
      ) : (
        <>
          {isLoaded(PatientInfo) ? (
            <>
              <PatientInfoHeader
                avatar={avatarCache.get(organizationId, patientId, PictureResolution["112x112"])}
                firstName={patientInfoData?.firstName}
                lastName={patientInfoData?.lastName}
                registryNumber={patientInfoData?.registryNumber}
                avatarSize={h && Number(h) >= 4 ? "large" : "extraSmall"}
              />

              <PatientInfoSection>
                <PatientInfoSectionItem
                  head={patientInfoData?.gender ? <Icon icon={patientInfoData.gender} /> : null}
                  body={
                    patientInfoData?.gender ? (
                      <PatientInfoValue size={w && Number(w) > 2 ? "large" : "small"}>
                        {tGeneral(`genderType.${patientInfoData?.gender}`)}
                      </PatientInfoValue>
                    ) : null
                  }
                  label={tGeneral("gender")}
                />

                <PatientInfoSectionItem
                  head={<Icon icon={"Bmi"} />}
                  body={
                    <PatientInfoValue size={w && Number(w) > 2 ? "large" : "small"}>
                      {patientInfoData?.bmi
                        ? numberFormatter({ value: patientInfoData?.bmi }).concat(
                            ` - ${BMICategory}`,
                          )
                        : t("na")}
                    </PatientInfoValue>
                  }
                  label={tGeneral("bmi")}
                />

                <PatientInfoSectionItem
                  head={<Icon icon={"Heart"} />}
                  body={
                    <PatientInfoValue size={w && Number(w) > 2 ? "large" : "small"}>
                      {patientInfoData?.years ?? t("na")}
                    </PatientInfoValue>
                  }
                  label={tGeneral("age")}
                />
              </PatientInfoSection>

              {shouldShowOrganizationalSection ? (
                <PatientInfoSection variant="grey">
                  <PatientInfoSectionItem
                    head={<Icon icon={"Organization"} style={{ color: "var(--primary-500)" }} />}
                    body={
                      <PatientInfoValue
                        size={w && Number(w) > 2 ? "large" : "small"}
                        link={
                          patientInfoData?.organization?.id === organizationId &&
                          can("manage-organization")
                            ? generatePath(settingsRoutePaths.organizationDetails, {
                                organizationId: patientInfoData?.organization?.id ?? "",
                                firstTab: "partners",
                              })
                            : undefined
                        }
                      >
                        <p title={patientInfoData?.organization?.name}>
                          {patientInfoData?.organization?.name}
                        </p>
                      </PatientInfoValue>
                    }
                    label={tOrganizational("organization")}
                  />

                  <PatientInfoSectionItem
                    head={
                      <Avatar
                        src={avatarCache.get(
                          patientInfoData?.organization?.id,
                          patientInfoData?.hcp?.id,
                          PictureResolution.details,
                          true,
                        )}
                        firstName={patientInfoData?.hcp?.firstName}
                        avatarLoading={false}
                        size="extraSmall"
                        className="PatientInfoWidget__avatar"
                      />
                    }
                    body={
                      <PatientInfoValue
                        size={w && Number(w) > 2 ? "large" : "small"}
                        link={
                          can("view-hcp-details") && patientInfoData?.hcp?.id
                            ? generatePath(mainRoutePaths.hcpDetails, {
                                organizationId: patientInfoData?.organization?.id ?? "",
                                hcpId: patientInfoData?.hcp?.id,
                              })
                            : undefined
                        }
                      >
                        <p
                          title={
                            patientInfoData?.hcp?.id
                              ? patientInfoData.hcp.firstName.concat(
                                  ` ${patientInfoData.hcp.lastName}`,
                                )
                              : undefined
                          }
                        >
                          {patientInfoData?.hcp?.id
                            ? patientInfoData.hcp.firstName.concat(
                                ` ${patientInfoData.hcp.lastName}`,
                              )
                            : t("na")}
                        </p>
                      </PatientInfoValue>
                    }
                    label={tOrganizational("hcp")}
                  />

                  <PatientInfoSectionItem
                    head={<DeviceImage />}
                    body={
                      <PatientInfoValue
                        size={w && Number(w) > 2 ? "large" : "small"}
                        link={
                          can("manage-device") && patientInfoData?.device?.id
                            ? generatePath(mainRoutePaths.deviceDetails, {
                                organizationId: patientInfoData?.organization?.id ?? "",
                                deviceId: patientInfoData?.device?.id,
                              })
                            : undefined
                        }
                      >
                        <p title={patientInfoData?.device?.serialNumber}>
                          {patientInfoData?.device?.id
                            ? (deviceTypeToSelectOptions().find(
                                el => el.value === patientInfoData.device?.name,
                              )?.title ?? patientInfoData?.device?.name)
                            : t("na")}
                        </p>
                      </PatientInfoValue>
                    }
                    label={tOrganizational("device")}
                  />
                </PatientInfoSection>
              ) : null}
            </>
          ) : (
            <RefreshFailedWidget show={isFailed(PatientInfo)} refreshCb={refreshWidgetData} />
          )}
        </>
      )}
    </div>
  );
}
