import { RadioOptions, SideModalElements } from "libs/ui";
import { useTranslation } from "react-i18next";
import { withDeviceToSelectOptions } from "utils/mappers/withDeviceToSelectOptions";

interface AssignedDeviceFilterProps {
  value?: boolean | null;
  onSelect: (value: boolean | null) => void;
}

export function AssignedDeviceFilter({ value, onSelect }: AssignedDeviceFilterProps) {
  const { t } = useTranslation("translation", { keyPrefix: "AssignedDeviceFilter" });
  const options = withDeviceToSelectOptions();

  return (
    <div className="FilterSection">
      <SideModalElements.SectionTitle>{t("title")}</SideModalElements.SectionTitle>
      <RadioOptions value={value} options={options} onSelect={onSelect} />
    </div>
  );
}
