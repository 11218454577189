import { TestsPerPatientTable } from "components/AppTables/TestsPerPatientTable";
import { Details } from "pages/PatientsPage/Details";
import { PatientDetailsServiceContextProvider } from "pages/PatientsPage/Details/Context";
import { EventEnrichedTabsForPatient } from "pages/PatientsPage/Details/EventEnrichedTabsForPatient";
import { All } from "pages/PatientsPage/Details/Graph/All";
import SerumCreatinine from "pages/PatientsPage/Details/Graph/SerumCreatinine";
import UrineCreatinine from "pages/PatientsPage/Details/Graph/UrineCreatinine";
import EGFR from "pages/PatientsPage/Details/Graph/EGFR";
import { GraphDataAPIContextProvider } from "pages/PatientsPage/Details/Graph/GraphDataAPIContext";
import { GraphTabsWithDataDownload } from "pages/PatientsPage/Details/Graph/GraphTabsWithDataDownload";
import { Parent } from "pages/PatientsPage/Parent";
import { applyTo } from "ramda";
import { Navigate, Outlet, Route } from "react-router";
import { mainRoutePaths } from "router";
import { AccessLevel } from "../utils/AccessLevel";
import UrineAlbumin from "pages/PatientsPage/Details/Graph/UrineAlbumin";
import Glucose from "pages/PatientsPage/Details/Graph/Glucose";
import { PatientsTableStateProvider } from "components/AppTables/AllPatientsTable/context";
import UACR from "pages/PatientsPage/Details/Graph/UACR";
import BMI from "pages/PatientsPage/Details/Graph/BMI";
import Height from "pages/PatientsPage/Details/Graph/Height";
import BloodPressure from "pages/PatientsPage/Details/Graph/BloodPressure";
import Weight from "pages/PatientsPage/Details/Graph/Weight";
import { PatientLaboratoryContextProvider } from "pages/PatientsPage/Details/LaboratoryContext";
import { Overview } from "pages/PatientsPage/Details/Overview";
import { OverviewAPIContext } from "pages/PatientsPage/Details/Overview/OverviewAPIContext";
import { DetailsPageContextProvider } from "components/PageTemplate/DetailsPage/context";
import SQUACR from "pages/PatientsPage/Details/Graph/SQUACR";

export const PatientsRoutes = (
  <Route
    path={mainRoutePaths.patients}
    element={
      <DetailsPageContextProvider>
        <Outlet />
      </DetailsPageContextProvider>
    }
  >
    <Route
      index
      element={
        <AccessLevel check={applyTo("manage-patient")}>
          <PatientsTableStateProvider>
            <Parent />
          </PatientsTableStateProvider>
        </AccessLevel>
      }
    />

    <Route
      path=":organizationId/:patientId/*"
      element={
        <OverviewAPIContext>
          <PatientDetailsServiceContextProvider>
            <PatientLaboratoryContextProvider>
              <GraphDataAPIContextProvider>
                <Details />
              </GraphDataAPIContextProvider>
            </PatientLaboratoryContextProvider>
          </PatientDetailsServiceContextProvider>
        </OverviewAPIContext>
      }
    >
      <Route path="overview" element={<Overview />} />
      <Route path="tests" element={<EventEnrichedTabsForPatient />}>
        <Route
          path="all"
          element={
            <TestsPerPatientTable
              appliedColumns={[
                "measurementType",
                "date",
                "testResult",
                "testedBy",
                "id",
                "testingMethod",
                "actions",
              ]}
            />
          }
        />
        <Route
          path="serumCreatinine"
          element={
            <TestsPerPatientTable
              measurementType="SerumCreatinine"
              measurementTypeItem="SerumCreatinine"
              appliedColumns={[
                "measurementType",
                "date",
                "serumCreatinine",
                "testedBy",
                "id",
                "testingMethod",
                "actions",
              ]}
            />
          }
        />
        <Route
          path="egfr"
          element={
            <TestsPerPatientTable
              measurementType="SerumCreatinine"
              measurementTypeItem="Egfr"
              appliedColumns={[
                "measurementType",
                "date",
                "eGFR",
                "testedBy",
                "id",
                "testingMethod",
                "actions",
              ]}
            />
          }
        />

        <Route
          path="bmi"
          element={
            <TestsPerPatientTable
              measurementType="BMI"
              measurementTypeItem="Bmi"
              appliedColumns={[
                "measurementType",
                "date",
                "bmi",
                "patient",
                "testedBy",
                "id",
                "testingMethod",
                "actions",
              ]}
            />
          }
        />

        <Route
          path="height"
          element={
            <TestsPerPatientTable
              measurementType="BMI"
              measurementTypeItem="Height"
              appliedColumns={[
                "measurementType",
                "date",
                "height",
                "patient",
                "testedBy",
                "id",
                "testingMethod",
                "actions",
              ]}
            />
          }
        />

        <Route
          path="weight"
          element={
            <TestsPerPatientTable
              measurementType="BMI"
              measurementTypeItem="Weight"
              appliedColumns={[
                "measurementType",
                "date",
                "weight",
                "patient",
                "testedBy",
                "id",
                "testingMethod",
                "actions",
              ]}
            />
          }
        />

        <Route
          path="bloodPressure"
          element={
            <TestsPerPatientTable
              measurementType="BloodPressure"
              appliedColumns={[
                "measurementType",
                "date",
                "bloodPressure",
                "testedBy",
                "id",
                "testingMethod",
                "actions",
              ]}
            />
          }
        />
        <Route
          path="glucose"
          element={
            <TestsPerPatientTable
              measurementType="Glucose"
              appliedColumns={[
                "measurementType",
                "date",
                "glucose",
                "testedBy",
                "id",
                "testingMethod",
                "actions",
              ]}
            />
          }
        />
        <Route
          path="urineCreatinine"
          element={
            <TestsPerPatientTable
              measurementType="UACR"
              measurementTypeItem="UrineCreatinine"
              appliedColumns={[
                "measurementType",
                "date",
                "urineCreatinine",
                "testedBy",
                "id",
                "testingMethod",
                "actions",
              ]}
            />
          }
        />
        <Route
          path="urineAlbumin"
          element={
            <TestsPerPatientTable
              measurementType="UACR"
              measurementTypeItem="UrineAlbumin"
              appliedColumns={[
                "measurementType",
                "date",
                "urineAlbumin",
                "testedBy",
                "id",
                "testingMethod",
                "actions",
              ]}
            />
          }
        />
        <Route
          path="uacr"
          element={
            <TestsPerPatientTable
              measurementType="UACR"
              measurementTypeItem="Uacr"
              appliedColumns={[
                "measurementType",
                "date",
                "uacr",
                "testedBy",
                "id",
                "testingMethod",
                "actions",
              ]}
            />
          }
        />

        <Route
          path="semiQuantitativeUacr"
          element={
            <TestsPerPatientTable
              measurementType="SemiQuantitativeUACR"
              measurementTypeItem="SemiQuantitativeUacr"
              appliedColumns={[
                "measurementType",
                "date",
                "squacr",
                "testedBy",
                "id",
                "testingMethod",
                "actions",
              ]}
            />
          }
        />
        <Route path="*" element={<Navigate to="all" replace />} />
      </Route>

      <Route path="graph" element={<GraphTabsWithDataDownload />}>
        <Route path="all" element={<All />} />
        <Route path="glucose" element={<Glucose />} />
        <Route path="serumCreatinine" element={<SerumCreatinine />} />
        <Route path="egfr" element={<EGFR />} />
        <Route path="bmi" element={<BMI />} />
        <Route path="height" element={<Height />} />
        <Route path="weight" element={<Weight />} />
        <Route path="bloodPressure" element={<BloodPressure />} />
        <Route path="urineCreatinine" element={<UrineCreatinine />} />
        <Route path="urineAlbumin" element={<UrineAlbumin />} />
        <Route path="uacr" element={<UACR />} />
        <Route path="semiQuantitativeUacr" element={<SQUACR />} />
        <Route path="*" element={<Navigate to="all" replace />} />
      </Route>
      <Route path="*" element={<Navigate to="tests" replace />} />
    </Route>
  </Route>
);
