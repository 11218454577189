import { Loader } from "libs/ui";
import { isDefaultModel, isFailed, isLoading } from "models/loadable";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FilterSection } from "../FilterSection";
import { CarnaApiQuery } from "config/apiQuery";
import { organizationCollectionToSelectOptions } from "utils/mappers/organizationCollectionToSelectOptions";
import { useApi } from "utils/hooks/useApi";

interface OrganizationsFilterSectionProps {
  checkedValues: string[];
  onFilterApply: (value: string) => void;
}

export function OrganizationsFilterSection({
  checkedValues,
  onFilterApply,
}: Readonly<OrganizationsFilterSectionProps>) {
  const { t } = useTranslation("translation", { keyPrefix: "OrganizationsFilterSection" });
  const [organizationsData, getOrganizations] = useApi(CarnaApiQuery.Organizations.get);

  useEffect(() => {
    if (isDefaultModel(organizationsData)) {
      getOrganizations({ limit: 100, page: 1 });
    }
  }, [getOrganizations, organizationsData]);

  if (isFailed(organizationsData)) {
    return <>{t("notAvailable")}</>;
  }

  return (
    <Loader loading={isLoading(organizationsData)}>
      <FilterSection
        title={t("organization")}
        options={organizationCollectionToSelectOptions(organizationsData)}
        checkedValues={checkedValues}
        onFilterApply={onFilterApply}
        withSearch={true}
      />
    </Loader>
  );
}
