import { useEffect, useMemo, useState } from "react";
import { HeatMapWidget } from "./HeatMapWidget";
import "./Overview.scss";
import { useOverviewAPIContext } from "./OverviewAPIContext";
import { PatientInfoWidget } from "./PatientInfoWidget";
import { RouteParams } from "router/model";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import { NotNullOrUndefined } from "utils/NotNullOrUndefined";
import { mainRoutePaths } from "router";
import { Layout, Layouts } from "react-grid-layout";
import { Breakpoint, DragAndDropGrid } from "components/DnD";
import { MeasurementWidget } from "./MeasurementWidget";
import { DraggableWidget } from "components/DnD/DraggableWidget";
import { useLocalStorage } from "utils/hooks/useLocalStorage";
import { INIT_LAYOUTS } from "./layoutModels";

export function Overview() {
  const { appConfig } = useGlobalConfigContext();
  const { organizationId = "", patientId = "" } = useParams<RouteParams["patientDetails"]>();
  const navigate = useNavigate();
  const { getWidgetData } = useOverviewAPIContext();

  const shouldShowPage = NotNullOrUndefined(appConfig).pages.PatientsPage.Details.ShowOverviewTab;

  useEffect(() => {
    if (!shouldShowPage) {
      navigate(
        generatePath(mainRoutePaths.patientDetails, {
          organizationId,
          patientId,
        }),
        { replace: true },
      );
    }
  }, [navigate, organizationId, patientId, shouldShowPage]);

  useEffect(() => {
    if (shouldShowPage) {
      getWidgetData(organizationId, patientId, [
        "HeatMap",
        "PatientInfo",
        "SerumCreatinine",
        "Egfr",
        // "Height", // ! Latest requirement don't want height to be in the rendered widget list
        "Weight",
        "Bmi",
        "Glucose",
        "BloodPressure",
        "UrineCreatinine",
        "UrineAlbumin",
        "Uacr",
        "SemiQuantitativeUacr",
      ]);
    }
  }, [getWidgetData, organizationId, patientId, shouldShowPage]);

  const { storageValue: latestSavedLayouts = INIT_LAYOUTS, setLocalStorageItem } = useLocalStorage<
    Layouts | undefined
  >("PatientOverviewLayout");

  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint | undefined>();

  const onLayoutChange = (_currentLayout: Layout[], allLayouts: Layouts) => {
    setLocalStorageItem(allLayouts);
  };

  const onBreakpointChange = (breakpoint: string, _cols: number) => {
    setCurrentBreakpoint(breakpoint as Breakpoint);
  };

  const items = useMemo(
    () =>
      latestSavedLayouts[currentBreakpoint ?? "col-3"].map(item => {
        switch (item.i) {
          case "HeatMap":
            return (
              <HeatMapWidget
                key={item.i}
                organizationId={organizationId}
                patientId={patientId}
                w={item.w}
                h={item.h}
              />
            );
          case "PatientInfo":
            return (
              <DraggableWidget key={item.i}>
                <PatientInfoWidget
                  w={item.w}
                  h={item.h}
                  organizationId={organizationId}
                  patientId={patientId}
                />
              </DraggableWidget>
            );
          case "SerumCreatinine":
            return (
              <MeasurementWidget
                key={"SerumCreatinine"}
                type="SerumCreatinine"
                organizationId={organizationId}
                patientId={patientId}
                w={item.w}
                h={item.h}
              />
            );
          case "Egfr":
            return (
              <MeasurementWidget
                key={"Egfr"}
                type="Egfr"
                organizationId={organizationId}
                patientId={patientId}
                w={item.w}
                h={item.h}
              />
            );
          case "Weight":
            return (
              <MeasurementWidget
                key={"Weight"}
                type="Weight"
                organizationId={organizationId}
                patientId={patientId}
                w={item.w}
                h={item.h}
              />
            );
          case "Bmi":
            return (
              <MeasurementWidget
                key={"Bmi"}
                type="Bmi"
                organizationId={organizationId}
                patientId={patientId}
                w={item.w}
                h={item.h}
              />
            );
          case "Height":
            return (
              <MeasurementWidget
                key={"Height"}
                type="Height"
                organizationId={organizationId}
                patientId={patientId}
                w={item.w}
                h={item.h}
              />
            );
          case "Glucose":
            return (
              <MeasurementWidget
                key={"Glucose"}
                type="Glucose"
                organizationId={organizationId}
                patientId={patientId}
                w={item.w}
                h={item.h}
              />
            );
          case "BloodPressure":
            return (
              <MeasurementWidget
                key={"BloodPressure"}
                type="BloodPressure"
                organizationId={organizationId}
                patientId={patientId}
                w={item.w}
                h={item.h}
              />
            );
          case "Uacr":
            return (
              <MeasurementWidget
                key={"Uacr"}
                type="Uacr"
                organizationId={organizationId}
                patientId={patientId}
                w={item.w}
                h={item.h}
              />
            );
          case "UrineAlbumin":
            return (
              <MeasurementWidget
                key={"UrineAlbumin"}
                type="UrineAlbumin"
                organizationId={organizationId}
                patientId={patientId}
                w={item.w}
                h={item.h}
              />
            );
          case "UrineCreatinine":
            return (
              <MeasurementWidget
                key={"UrineCreatinine"}
                type="UrineCreatinine"
                organizationId={organizationId}
                patientId={patientId}
                w={item.w}
                h={item.h}
              />
            );
          case "SemiQuantitativeUacr":
            return (
              <MeasurementWidget
                key={"SemiQuantitativeUacr"}
                type="SemiQuantitativeUacr"
                organizationId={organizationId}
                patientId={patientId}
                w={item.w}
                h={item.h}
              />
            );
          default:
            return null;
        }
      }),
    [currentBreakpoint, latestSavedLayouts, organizationId, patientId],
  );

  return (
    <div className="Overview">
      <DragAndDropGrid
        layouts={latestSavedLayouts}
        onLayoutChange={onLayoutChange}
        onBreakpointChange={onBreakpointChange}
      >
        {items}
      </DragAndDropGrid>
    </div>
  );
}
