import classNames from "classnames";
import { SyncTableButton } from "components/AppTables/common/SyncTableButton";
import { FilterSearchTogglerBar } from "components/FilterSearchTogglerBar";
import { Button } from "libs/ui";
import { useOnClickOutside } from "libs/ui/hooks";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ControlsProps } from "../model";
import "./Controls.scss";
import { DownloadReportButton } from "./DownloadReportButton";

export function Controls({
  className,
  openAddModal,
  onFilterToggle,
  searchField,
  filtersCount,
  onRefreshTable,
  onDownloadReport,
}: ControlsProps) {
  const { t } = useTranslation("translation", { keyPrefix: "ParentPage" });
  const divRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const onClickChange = () => setShowDropdown(prevValue => !prevValue);

  useOnClickOutside(divRef, showDropdown, () => onClickChange());

  return (
    <div className={classNames(className, "Controls")} data-testid="Controls">
      <FilterSearchTogglerBar
        searchField={searchField}
        onFilterToggle={onFilterToggle}
        filtersCount={filtersCount}
      />

      {onRefreshTable ? <SyncTableButton onClick={onRefreshTable} /> : null}

      {onDownloadReport ? <DownloadReportButton onClick={onDownloadReport} /> : null}

      {openAddModal ? (
        <Button
          data-testid="OpenAddModal"
          buttonType="primary"
          buttonSize="medium"
          buttonIcon={{ icon: "Plus" }}
          onClick={openAddModal}
        >
          {t("buttonToggleAdd")}
        </Button>
      ) : null}
    </div>
  );
}
