import { TestFormModel } from "components/Forms/Test/model";
import _ from "lodash-es";

export const getTestDataWithoutNone = (testData: TestFormModel) => {
  const testDataWithoutNone = _.cloneDeep(testData);
  testDataWithoutNone.measurements = testDataWithoutNone.measurements.filter(
    measurement => measurement.measurementType !== "None",
  );
  return testDataWithoutNone;
};
