import { FilterSection } from "components/AppTables/common/Filter/FilterSection";
import { OrganizationsFilterSection } from "components/AppTables/common/Filter/OrganizationsFilterSection";
import { TableFilter } from "components/AppTables/common/Filter/TableFilter";
import { useTranslation } from "react-i18next";
import { AssignedDeviceFilter } from "../../Filter/AssignedDeviceFilter";
import { UserStatusFilter } from "../../Filter/UserStatusFilter";
import { HCPsTableFilterProps, HCPs_FILTER_OPTIONS } from "../model";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import React from "react";
import { StatesFilterSection } from "../../Filter/StatesFilterSection";

export function HCPsTableFilter({
  tableService,
  filterSideModelState,
}: Readonly<HCPsTableFilterProps>) {
  const { t: tColumns } = useTranslation("translation", { keyPrefix: "HCPTable.columns" });
  const { isAdmin } = useAdditionalAuthInformationContext();

  return (
    <TableFilter tableService={tableService} filterSideModelState={filterSideModelState}>
      {({
        activeFilters,
        appliedFilters,
        onNewFilterApplied,
        onNewRadioFilterApplied,
        onSingleFilterClear,
      }) => (
        <>
          {activeFilters.map(el => {
            if (el === "contains") {
              return null;
            }

            if (el === "organizationIds") {
              return isAdmin ? (
                <OrganizationsFilterSection
                  key={el}
                  checkedValues={appliedFilters[el] ?? []}
                  onFilterApply={value => onNewFilterApplied(el, value)}
                />
              ) : null;
            }

            if (el === "withDevice") {
              return (
                <AssignedDeviceFilter
                  key={el}
                  value={appliedFilters[el]}
                  onSelect={value => onNewRadioFilterApplied(el, value)}
                />
              );
            }

            if (el === "userStatuses") {
              return (
                <UserStatusFilter
                  key={el}
                  values={appliedFilters[el] ?? []}
                  onFilterApply={value => onNewFilterApplied(el, value)}
                />
              );
            }

            // states can't be standalone filter
            if (el === "states") {
              return null;
            }

            if (el === "countries") {
              return (
                <React.Fragment key={"countries"}>
                  <FilterSection
                    title={tColumns("countries")}
                    options={HCPs_FILTER_OPTIONS()["countries"]}
                    checkedValues={appliedFilters["countries"] ?? []}
                    onFilterApply={value => onNewFilterApplied("countries", value)}
                    withSearch={true}
                  />

                  <StatesFilterSection
                    checkedValues={appliedFilters["states"] ?? []}
                    countries={appliedFilters["countries"] ?? []}
                    onFilterApply={value => onNewFilterApplied("states", value)}
                    onSingleFilterClear={() => onSingleFilterClear("states")}
                  />
                </React.Fragment>
              );
            }

            return (
              <FilterSection
                key={el}
                title={tColumns(el)}
                options={HCPs_FILTER_OPTIONS()[el]}
                checkedValues={appliedFilters[el] ?? []}
                onFilterApply={value => onNewFilterApplied(el, value)}
              />
            );
          })}
        </>
      )}
    </TableFilter>
  );
}
