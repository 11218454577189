import { FilterSection } from "components/AppTables/common/Filter/FilterSection";
import { TableFilter } from "components/AppTables/common/Filter/TableFilter";
import { useTranslation } from "react-i18next";
import { OrganizationsFilterSection } from "../../Filter/OrganizationsFilterSection";
import { UserStatusFilter } from "../../Filter/UserStatusFilter";
import { AdministratorsTableFilterProps, ADMINISTRATORS_FILTER_OPTIONS } from "../model";
import { useAdditionalAuthInformationContext } from "context/AdditionalAuthInformationContext";
import { useGlobalConfigContext } from "context/GlobalConfigContext";
import React from "react";
import { StatesFilterSection } from "../../Filter/StatesFilterSection";

export function AdministratorsTableFilter({
  filterSideModelState,
  tableService,
}: Readonly<AdministratorsTableFilterProps>) {
  const { t: tColumns } = useTranslation("translation", {
    keyPrefix: "AdministratorsTable.columns",
  });
  const { isAdmin } = useAdditionalAuthInformationContext();
  const { appConfig } = useGlobalConfigContext();

  return (
    <TableFilter tableService={tableService} filterSideModelState={filterSideModelState}>
      {({ activeFilters, appliedFilters, onNewFilterApplied, onSingleFilterClear }) => (
        <>
          {activeFilters.map(el => {
            switch (el) {
              case "contains":
              case "states":
                return null;
              case "countries":
                return (
                  <React.Fragment key={"countries"}>
                    <FilterSection
                      title={tColumns("countries")}
                      options={ADMINISTRATORS_FILTER_OPTIONS()["countries"]}
                      checkedValues={appliedFilters["countries"] ?? []}
                      onFilterApply={value => onNewFilterApplied("countries", value)}
                      withSearch={true}
                    />

                    <StatesFilterSection
                      checkedValues={appliedFilters["states"] ?? []}
                      countries={appliedFilters["countries"] ?? []}
                      onFilterApply={value => onNewFilterApplied("states", value)}
                      onSingleFilterClear={() => onSingleFilterClear("states")}
                    />
                  </React.Fragment>
                );
              case "organizationIds":
                return isAdmin ? (
                  <OrganizationsFilterSection
                    key={el}
                    checkedValues={appliedFilters[el] ?? []}
                    onFilterApply={value => onNewFilterApplied(el, value)}
                  />
                ) : null;

              case "userStatuses":
                return (
                  <UserStatusFilter
                    includeDeletedUsers={appConfig?.general.includeDeletedUsers}
                    key={el}
                    values={appliedFilters[el] ?? []}
                    onFilterApply={value => onNewFilterApplied(el, value)}
                  />
                );
              case "userRoles":
                return (
                  <FilterSection
                    key={el}
                    title={tColumns(el)}
                    options={ADMINISTRATORS_FILTER_OPTIONS()[el]}
                    checkedValues={appliedFilters[el] ?? []}
                    onFilterApply={value => onNewFilterApplied(el, value)}
                  />
                );

              default:
                return (
                  <FilterSection
                    key={el}
                    title={tColumns(el)}
                    options={ADMINISTRATORS_FILTER_OPTIONS()[el]}
                    checkedValues={appliedFilters[el] ?? []}
                    onFilterApply={value => onNewFilterApplied(el, value)}
                  />
                );
            }
          })}
        </>
      )}
    </TableFilter>
  );
}
