import { Button, useTooltip } from "libs/ui";
import { useHover } from "@floating-ui/react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface SyncTableButtonProps {
  onClick: () => void;
  className?: string;
}

export function SyncTableButton({ onClick, className }: SyncTableButtonProps) {
  const { t } = useTranslation("translation", { keyPrefix: "SyncTableButton" });

  const [ref, getReferenceProps, Tooltip] = useTooltip({
    placement: "bottom",
    interactionProps: [useHover],
  });

  return (
    <>
      <Button
        className={classNames(className)}
        buttonType="white"
        buttonSize="medium"
        buttonIcon={{ icon: "Sync" }}
        onClick={onClick}
        ref={ref}
        {...getReferenceProps()}
      />
      <Tooltip>{t("syncTable")}</Tooltip>
    </>
  );
}
