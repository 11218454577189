import { SyncTableButton } from "components/AppTables/common/SyncTableButton";
import { PropsWithChildren } from "react";

interface PageControlsProps {
  isEmptyState: boolean;
  isTableFailed: boolean;
  isTableLoading: boolean;
  onRefreshTable: (() => void) | undefined;
}

export function PageControls({
  isEmptyState,
  isTableFailed,
  isTableLoading,
  onRefreshTable,
  children,
}: Readonly<PropsWithChildren<PageControlsProps>>) {
  const showControls = !isTableFailed && !isEmptyState;

  if (isTableLoading) {
    return null;
  }

  if (!showControls) {
    return onRefreshTable ? <SyncTableButton onClick={onRefreshTable} /> : null;
  }

  return children;
}
