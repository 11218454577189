import classNames from "classnames";
import { Checkbox, FieldLoader, SideModalElements } from "libs/ui";
import { useCallback, useState } from "react";
import "./FilterSection.scss";
import { v4 } from "uuid";
import { SearchField } from "components/SearchField";
import { Option } from "libs/ui/Select/Select.model";
interface FilterSectionProps<T> {
  title: string;
  options: Option<T>[];
  checkedValues: T[];
  onFilterApply: (value: T) => void;
  isLoading?: boolean;
  withSearch?: boolean;
}

export function FilterSection<T>({
  title,
  options,
  checkedValues,
  onFilterApply,
  isLoading,
  withSearch = false,
}: Readonly<FilterSectionProps<T>>) {
  const checkedValuesToArray = Array.isArray(checkedValues) ? checkedValues : [checkedValues];
  const [searchValue, setSearchValue] = useState<string | undefined>("");
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      onFilterApply(value as unknown as T);
    },
    [onFilterApply],
  );

  const selectedOptionsReversed = options
    .filter(option => checkedValuesToArray.some(checked => checked === option.value))
    .reverse();

  const uncheckedOptions = options
    .filter(option => checkedValuesToArray.every(checked => checked !== option.value))
    .filter(
      el =>
        el.title.toLowerCase().includes((searchValue ?? "").toLowerCase()) ||
        String(el.value).toLowerCase() === (searchValue ?? "").toLowerCase(),
    );

  if (isLoading) {
    return <FieldLoader />;
  }

  return (
    <div
      className={classNames("FilterSection", {
        "FilterSection--with-checked": !!selectedOptionsReversed.length,
        "FilterSection--all-checked": selectedOptionsReversed.length === options.length,
      })}
    >
      <SideModalElements.SectionTitle>{title}</SideModalElements.SectionTitle>

      <ul className="FilterSection__list">
        {selectedOptionsReversed.map(option => (
          <li className="FilterSection__item" key={v4()}>
            <Checkbox
              label={option.title}
              checked={checkedValuesToArray.some(el => el === option.value)}
              value={option.value as unknown as string}
              onChange={onChange}
            />
          </li>
        ))}
      </ul>

      {!!selectedOptionsReversed.length && <SideModalElements.Separator />}

      {withSearch ? (
        <SearchField initValue={searchValue} onInputChange={setSearchValue} debounceTime={100} />
      ) : null}

      <ul className="FilterSection__list">
        {uncheckedOptions.map(option => (
          <li className="FilterSection__item" key={v4()}>
            <Checkbox
              label={option.title}
              checked={checkedValuesToArray.some(el => el === option.value)}
              value={option.value as unknown as string}
              onChange={onChange}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
